.overlay {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	display: none;
	background-color: rgba($color-black, 0.8);

	&__show {
		height: 100vh;
		overflow-y: auto;
		display: block;
	}

	&__close {
		position: fixed;
		width: 4rem;
		height: auto;
		padding: 0.5rem;
		right: 2rem;
		top: 2rem;
		border-radius: 50%;
		border: solid 3px $color-white;
		content: url($imagePath + "/plus.svg");
		transform: rotate(45deg);
	}

	&__content {
		position: relative;
		width: 40rem;
		max-width: 90%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
