.loading {
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 0.4rem solid $color-bg-blue-2;
  border-top: solid 0.4rem $color-blue-2;
  border-right: solid 0.4rem $color-blue-2;
  border-left: solid 0.4rem $color-blue-2;
  animation: spin 1s infinite ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
