.laws-wrapper {
    // max-width: calc(100% - 11.5rem);
    // margin: auto;

    // @include screenSize(S) {
    //   width: calc(100% - 5rem);
    // }
}

.content-wrapper {
    padding-top: 10rem;
    position: relative;
    // top: 4rem;
    width: 100%;
    background-color: #d2dee9;
}
