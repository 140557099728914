.top {
	position: fixed;
	top: 0;
	width: 100%;
	padding: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	box-shadow: 0px 4px 4px rgba(12, 48, 129, 0.05);
	z-index: 5;
}

.logout {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 1rem 1.5rem;
	background-color: $color-blue-3;
	color: $color-white;
	font-size: 1.6rem;
	border-radius: 0.8rem;
	cursor: pointer;

	&__admin {
		position: absolute;
		bottom: 10%;
		background-color: transparent;
		color: $color-text-gray-4;
	}

	&__text {
		@include maxSize(M) {
			display: none;
		}
	}
}
