@keyframes loader {
	0% {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
	}
}

.auth {
	display: flex;
	height: 100vh;

	&__error {
		font-size: 1.6rem;
		text-align: left;
		color: $color-red-1;
	}

	&__success {
		font-size: 1.6rem;
		text-align: left;
		color: $color-blue-3;
	}

	& > * {
		flex: 0 1 100%;
	}

	&__cards {
		width: 70%;
		display: grid;
		gap: 2rem;
		position: relative;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@include screenSize(S) {
			width: 80%;
		}
	}

	&__card {
		padding: 4rem;
		background-color: $color-white;
		border: 1px solid #eeeeee;
		border-radius: 2rem;

		&--support {
			display: grid;
			gap: 2rem;
			padding: 1rem;
			font-size: 1.2rem;
		}

		&--heading {
			font-size: 2.5rem;
		}

		&--subheading {
			font-size: 1.5rem;
			color: rgba($color-black, 0.6);
			font-weight: 400;
		}

		&--form {
			display: grid;
			gap: 2rem;
		}
	}
	&__form-wrapper {
		// width: 50%;
		overflow-x: hidden;
		background-color: #faf9f6;
		text-align: center;
	}

	&__form {
		display: grid;
		gap: 2rem;
	}

	&__form-group {
		position: relative;
		display: grid;
		text-align: left;
		gap: 0.4rem;
	}

	&__form-label {
		font-size: 1.2rem;
		font-weight: 700;
	}

	&__form-control {
		height: 4rem;
		padding: 1rem 1.5rem;
		border: 1px solid #64748b93;
		border-radius: 0.4rem;
		outline: $color-blue-1;

		&:focus {
			border-color: $color-blue-3;
		}

		&--error {
			border-color: $color-red-1;
		}
	}

	&__form-icon {
		display: none;
		position: absolute;
		bottom: 22%;
		right: 1.5rem;
		cursor: pointer;
		&--show {
			display: block;
		}

		&--hide {
			height: 1.4rem;
			width: 1.6rem;
			display: block;
		}
	}

	&__form-check {
		display: flex;
		gap: 1rem;
		align-items: center;
	}

	&__options {
		display: flex;
		justify-content: space-between;
	}

	&__forgot {
		color: $color-blue-3;
	}

	&__form-button {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		color: $color-white;
		padding: 1.5rem 0.5rem;
		background-color: $color-blue-3;
		border: none;
		border-radius: 5px;
	}

	&__spinner {
		display: block;
		width: 2.5rem;
		height: 2.5rem;
		border: 0.5rem solid transparent;
		border-top: 0.5rem solid $color-white;
		border-bottom: 0.5rem solid $color-white;
		border-radius: 50%;
		animation: loader 500ms infinite;
	}

	&__contacts {
		display: flex;
		gap: 2rem;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__support {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		align-items: flex-start;

		&--heading {
			font-size: 2rem;
			text-align: left;
		}
	}

	&__hero {
		// width: 50%;
		padding: 8rem 0;
		padding-left: 5%;
		display: grid;
		gap: 2rem;
		overflow: hidden;
		background: rgba(47, 104, 233, 0.2);

		@include screenSize(S) {
			display: none;
		}

		&--headings {
			text-align: left;
			display: grid;
			gap: 2rem;
			width: 70%;

			h3 {
				font-size: 2.4rem;
				color: $color-black;
				font-weight: 700;
			}
			h4 {
				font-size: 2rem;
				color: rgba($color-black, 0.6);
			}
		}

		&--image {
			height: 80%;
			width: 120%;

			@include minSize(XL) {
				height: 90%;
				width: 120%;
			}

			img {
				height: 100%;
				width: 100%;
			}
		}
	}
	button {
		cursor: pointer;
	}
}
