.filter {
	position: absolute;
	width: max-content;
	right: 0;
	display: none;
	gap: 2rem;
	padding: 4rem 2.4rem;
	border-radius: 0.4rem;
	background-color: $color-white;

	&__visible {
		display: grid;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		font-size: 2rem;
	}

	&__close {
		cursor: pointer;
	}

	&__options {
		display: grid;
		gap: 2rem;
		background-color: $color-white;
	}

	&__option {
		width: 20rem;
		display: grid;
		gap: 1rem;
		padding: 1.6rem 2rem;
		background-color: rgba($color-bg-gray-2, 0.5);
		color: $color-text-gray-1;
		border-radius: 0.4rem;
		transition: opacity 300ms;

		&--active {
			border: solid 1px rgba($color-blue-3, 1);
		}

		&--inactive {
			opacity: 0.5;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__desc {
		font-size: 1.2rem;
		font-weight: bold;
	}

	&__parameter {
		padding: 0.7rem 1.4rem;
		background-color: $color-white;
		color: $color-text-gray-3;
		font-size: 1.2rem;
		border-radius: 0.2rem;
		border-color: rgba($color-blue-3, 0.4);
		outline: rgba($color-bg-gray-2, 0.5);
	}

	&__button {
		padding: 1rem;
		background-color: $color-blue-3;
		color: $color-white;
		border: none;
		border-radius: 0.4rem;
		cursor: pointer;

		&:disabled {
			background-color: $color-text-gray-3;
			color: $color-black;
			cursor: not-allowed;
		}
	}
}
