.dashboard {
	height: 100vh;
	overflow-y: hidden;

	&__topbar {
		height: 6rem;
		display: flex;
		align-items: center;
		box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.04);
		background-color: $color-white;
		border-bottom: solid 1px #eee;
		z-index: 2;

		& > * {
			padding: 0 5%;
		}
	}
	&__logo {
		display: flex;
		align-items: center;
		gap: 2rem;
		font-size: 1.2rem;
	}
	&__title {
		font-size: 1.8rem;
	}

	&__view {
		height: 100vh;
		overflow-y: auto;
		display: grid;
		grid-template-columns: 1fr 5fr;
		background-color: $color-bg-gray-2;

		@include maxSize(M) {
			grid-template-columns: auto 5fr;
		}
	}

	&__content {
		// position: relative;
		height: 90vh;
		padding: 4rem;
		overflow-y: auto;

		@include maxSize(M) {
			padding: 4rem 2rem;
		}
	}
}
