.menu {
	height: 100%;
	width: 100%;
	padding: 2rem;
	background-color: $color-white;

	@include maxSize(M) {
		padding: 2rem 1rem;
	}

	&__items {
		margin-inline-start: 0;
		display: grid;
		gap: 3rem;
	}

	&__icon {
		&--overview {
			content: url($imagePath + "/menu.svg");
		}
		&--users {
			content: url($imagePath + "/users.svg");
		}
		&--states {
			content: url($imagePath + "/structure.svg");
		}
		&--transactions {
			content: url($imagePath + "/transactions.svg");
		}
	}

	&__item {
		width: 100%;
		padding: 1.2rem 0.8rem;
		display: flex;
		gap: 1rem;
		font-size: 1.8rem;
		color: $color-text-gray-4;
		transition: all 500ms;

		@include maxSize(M) {
			width: max-content;
		}

		&--active,
		&:hover {
			background-color: $color-blue-3;
			color: $color-white;
			border-radius: 0.4rem;
		}

		&:hover > .menu__icon,
		&--active > .menu__icon {
			&--overview {
				content: url($imagePath + "/menu-active.svg");
			}
			&--users {
				content: url($imagePath + "/users-active.svg");
			}
			&--states {
				content: url($imagePath + "/structure-active.svg");
			}
			&--transactions {
				content: url($imagePath + "/transactions-active.svg");
			}
		}

		span:nth-child(2) {
			@include maxSize(M) {
				display: none;
			}
		}

		// &--pending {
		// 	background: red;
		// }
	}
}
