$color-white: #fff;
$color-bg-gray-1: #f4f4f4;
$color-bg-gray-2: #e6e6e6;
$color-bg-gray-3: #415276;
$color-text-gray-1: #00050bbd;
$color-text-gray-2: #232323;
$color-text-gray-3: #808191;
$color-text-gray-4: #6e7c87;
$color-blue-1: #040849;
$color-blue-2: #6659ff;
$color-blue-3: #2f68e9;

$color-bg-blue-1: #369eff0c;
$color-bg-blue-2: #f5f8fb;
$color-bg-blue-3: #ebf5ff;
$color-green: #5bc897;
$color-pink: #ff7598;
$color-orange-1: #ff8231;
$color-orange-2: #f9cb84;
$color-orange-3: #ff7816;
$color-red-1: #dc2626;
$color-red-2: #f23e3e;
$color-red-3: #e80000;
$color-baby-blue: #f0f3ff;
$color-black: #000000;
$color-purple-1: #9b17d9;
