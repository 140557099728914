.options {
	width: max-content;
	position: absolute;
	top: 0rem;
	right: 5rem;
	display: none;
	font-size: 1.4rem;
	background-color: $color-white;
	box-shadow: 0px 4px 6px rgba($color-black, 21%);
	color: $color-text-gray-2;
	z-index: 1;

	&__visible {
		display: block;
	}
}

.option {
	padding: 1rem 1.5rem;
	&:hover {
		background-color: $color-blue-3;
		color: $color-white;
	}
}
