*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%; //   1rem
}
body {
	box-sizing: border-box;
	font-family: "DM Sans", Arial, Helvetica, sans-serif;
}
a {
	text-decoration: none;
	color: inherit;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

*::-webkit-scrollbar {
	width: 9px;
	height: 6px;
	background-color: $color-bg-gray-1;
}
*::-webkit-scrollbar-thumb {
	background-color: $color-blue-2;
}
