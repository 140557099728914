main {
	text-align: center;
	vertical-align: center;
	line-height: 100vh;
}
.date-picker {
	height: 300px;
	overflow-y: scroll;
}

.calendar {
	width: 300px;
	display: flex;
	flex-wrap: wrap;
	margin: 10px;
}

.month {
	margin: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	flex-basis: calc(33.33% - 20px);
}

.month-name {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
}

.weekdays {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.week {
	display: flex;
}

.date {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
}

.date.disabled {
	opacity: 0.3;
	cursor: default;
}

.date.selected {
	background-color: #007bff;
	color: #fff;
}
.date-picker {
	height: 300px;
}
