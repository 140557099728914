.overview {
	width: 100%;
	padding-bottom: 3rem;
	margin: auto;
	display: grid;
	grid-template-rows: auto 1fr;
	gap: 7rem;

	&__response {
		position: absolute;
		width: auto;
		padding: 1rem 2rem;
		font-size: 1.4rem;
		top: 7%;
		right: 0;
		// display: none;
		background-color: transparent;
		cursor: pointer;
		color: transparent;
		transition: all 300ms;
		border-radius: 4px 0 0 4px;

		&--error,
		&--success {
			color: $color-white;
		}

		&--error {
			background-color: $color-red-1;
		}

		&--success {
			background-color: $color-blue-3;
		}
	}

	& > * {
		min-width: 0;
		min-height: 0;
	}

	&__cards {
		display: grid;
		gap: 2rem;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}

	&__users {
		display: grid;
		gap: 3rem;
		grid-template-rows: auto 1fr;
	}

	&__actions {
		display: flex;
		gap: 2rem;
		justify-content: space-between;

		@include maxSize(M) {
			flex-direction: column;
		}
	}

	&__view {
		flex: 0 1 45%;
		display: grid;
		gap: 1.5rem;
		grid-template-columns: 4fr 1fr;
	}

	&__search,
	&__filter {
		position: relative;
		padding: 1.4rem;
		display: flex;
		align-items: center;
		gap: 1rem;
		height: 4.4rem;
		border: solid 1px $color-text-gray-3;
		border-radius: 0.8rem;
		background-color: $color-white;
		font-size: 1.4rem;
	}

	&__select {
		background: transparent;
		border: transparent;
		outline: none;
		font-size: 1.5rem;
	}

	&__icon {
		&--search {
			content: url($imagePath + "/search.svg");
		}

		&--plus {
			padding: 0.5rem;
			background: rgba(255, 255, 255, 0.24);
			content: url($imagePath + "/plus.svg");
			border-radius: 0.3rem;
		}
	}

	&__input {
		width: 100%;
		height: 100%;
		outline: transparent;
		border: transparent;
	}

	&__create {
		width: max-content;
		padding: 0.8rem 1.2rem;
		display: flex;
		align-items: center;
		gap: 1.23rem;
		color: $color-white;
		border-radius: 0.4rem;
		font-size: 1.5rem;
		background-color: $color-blue-3;
		cursor: pointer;
	}
}
table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 0.3rem;
	overflow-x: auto;
}
thead {
	border-bottom: solid 1px $color-bg-gray-3;
}
tr {
	background-color: $color-white;
}

th,
td {
	padding: 1rem 1.6rem;
	position: relative;
	text-align: left;
	font-size: 1.4rem;
	font-weight: 400;
	word-wrap: break-word;
	overflow-wrap: anywhere;

	@include screenSize(S) {
		padding: 1rem 0.5rem;
	}
}
th {
	color: $color-black;
}
td {
	color: $color-text-gray-4;

	&:last-child {
		font-size: 3rem;
		cursor: pointer;
	}
}
