.heading {
	padding: 2rem 0;
	display: flex;
	justify-content: space-between;
}

.summary {
	max-width: calc(100% - 11.5rem);
	margin: auto;
	display: grid;
	gap: 2rem;

	@include screenSize(S) {
		width: calc(100% - 5rem);
	}

	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 3rem;
		flex-wrap: wrap;
	}

	&__interval {
		font-size: 20px;
		color: #000;
	}

	&__filter {
		position: relative;

		&--options {
			display: flex;
			gap: 2rem;
			flex-wrap: wrap;
		}

		&--search {
			padding: 0.4rem 1.2rem;
			border: solid 1px transparent;

			&:hover {
				color: $color-blue-2;
				background-color: #fff;
				border: solid 1px $color-blue-2;
			}
		}
		&--icon,
		&--search {
			width: auto;
			border-radius: 0.5rem;
			background-color: $color-blue-2;
			color: #fff;
			display: flex;
			align-items: center;
			gap: 1rem;
			font-weight: 500;
			cursor: pointer;
			transition: all 400ms;
		}

		&--icon {
			padding: 0.8rem 1.2rem;
		}

		&--img {
			padding: 0.4rem 0.8rem;
			background: rgba(255, 255, 255, 0.24);
			border-radius: 0.3rem;
			display: flex;
		}

		&--text {
			position: relative;
			font-size: 1.5rem;
			display: block;
		}

		&--periods {
			position: absolute;
			display: grid;
			width: fit-content;
			visibility: hidden;
			background-color: #fff;
			font-size: 1.4rem;
			box-shadow: 0px 4px 4px rgba(12, 48, 129, 0.05);
			border-radius: 4px;
		}

		&--period {
			min-width: max-content;
			padding: 1rem;

			color: $color-text-gray-2;
			cursor: pointer;

			&:hover {
				background-color: $color-blue-2;
				color: #fff;
			}
		}

		&--visible {
			visibility: visible;
		}

		&--date {
			width: auto;
			padding: 0.5rem;
			display: flex;
			gap: 1rem;
			background-color: $color-white;
			border-radius: 1rem;
		}

		&--start,
		&--end {
			position: relative;
			display: flex;
			align-items: center;
			width: auto;
			border-radius: 0.5rem;
		}
	}

	&__cards {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		grid-gap: 3em;
		background-color: #d2dee9;
	}

	&__card {
		padding: 2rem 1.25rem;
		display: flex;
		gap: 1rem;
		align-items: center;
		background-color: $color-bg-blue-2;
		border-radius: 1rem;

		.card-icon {
			width: max-content;
			height: max-content;
			padding: 1rem;
			display: flex;
			border-radius: 50%;

			&--green {
				background: linear-gradient(
					158.06deg,
					#15671d 16.86%,
					#11a21f 96.89%
				);
			}
			&--pink {
				background: $color-pink;
			}
			&--blue {
				background: $color-blue-1;
			}

			&--orange {
				background: linear-gradient(98.24deg, #f9cb84 0%, #ff7816 100%);
			}

			img {
				object-fit: none;
			}
		}
		.card-text {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.4rem;
		}
		.card-label {
			font-size: 1.6rem;
			color: $color-text-gray-1;

			&__super {
				font-size: 2.8rem;
			}
		}
		.card-value {
			font-size: 1.7rem;
			font-weight: 500;
		}
	}
}

.laws {
	width: 100%;
	max-width: calc(100% - 11.5rem);
	padding: 3rem 0;
	margin: auto;
	margin-top: 7rem;
	display: grid;
	grid-template-columns: 1fr;
	gap: 3rem;
	background: #d2dee9;

	@include screenSize(S) {
		width: calc(100% - 5rem);
	}

	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
	.heading-title {
		font-size: 1.8rem;
		color: #000;
	}
	.heading-options {
		display: flex;
		gap: 2rem;
		flex-wrap: wrap;
	}
	.search {
		width: 30rem;
		padding: 0.75rem;
		background-color: $color-bg-gray-1;
		border-radius: 1rem;
		display: flex;
		align-items: center;

		&__desc {
			display: flex;
			gap: 0.5rem;
			align-items: center;

			&--icon {
			}

			&--text {
				color: $color-text-gray-3;
				padding-right: 0.2rem;
				border-right: 0.2rem solid rgba(0, 0, 0, 0.4);
				font-size: 1.2rem;
			}
		}

		&__input {
			width: 100%;
			padding-left: 0.5rem;
			font-size: 1.6rem;
			background: transparent;
			border: none;
			outline: none;
			font-size: 1.2rem;
		}
	}

	&__container {
		position: relative;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
		gap: 2rem;
	}

	&__card {
		padding: 2.4rem;
		display: flex;
		gap: 1rem;
		border-radius: 8px;
		background-color: #fff;
		transition: all 400ms;

		&:hover {
			background-color: #369eff67;

			& > .description {
				color: #fff;
			}
		}

		.cover {
			width: 30%;
			align-self: flex-start;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.description {
			width: 70%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			color: $color-text-gray-2;
			font-size: 1.4rem;
		}

		.title {
			@include text_truncate(4);
		}

		.sales {
			font-size: 1.4rem;
		}

		.rating {
		}
	}
}
