.react-datepicker {
	padding: 2rem !important;
	border-radius: 1rem;
	overflow: hidden;
	font-family: "DM Sans" !important;
	border: solid 2px transparent !important;

	// &__view-calendar-icon {
	// 	width: 100% !important;
	// }

	&__navigation {
		top: 2rem !important;

		&--next {
			right: 2rem !important;
		}

		&--previous {
			left: 2rem !important;
		}

		&:hover {
			color: $color-bg-blue-1 !important;
		}
	}

	&__navigation-icon {
		&::before {
			height: 1.4rem;
			width: 1.4rem;
		}
	}

	&__header {
		display: grid;
		gap: 0.875rem;
		background-color: $color-white;
	}

	&__year-read-view {
		font-size: 1.5rem;
		color: $color-text-gray-3;
		&:hover {
			color: $color-bg-blue-1;
		}

		&--down-arrow {
			// border-color: $color-orange;
			margin-top: 0.2rem;
			height: 1rem;
			width: 1rem;
		}
	}

	&__year-dropdown {
		// padding: 0 1rem;
		width: 10rem;
		left: 50%;
		transform: translateX(-50%);
		background-color: $color-white;
		font-size: 1.5rem;
		display: grid;
		gap: 0.5rem;

		border: solid 1px $color-blue-1;

		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 3px;
		}
	}

	&__year-option {
		&:hover {
			background-color: $color-blue-1;
			color: $color-white;
		}
	}

	&__input-container {
		input {
			width: 100% !important;
			border: transparent !important;
			outline: none;
		}
	}

	&__calendar-icon {
		padding: 0 !important;
		height: 1.5rem;
		width: 1.5rem;
		right: 0;
	}

	&__view-calendar-icon {
		input {
			padding: 0 !important;
			background-color: transparent;
		}
	}
	&__month-container {
		background-color: $color-white !important;
	}

	&__portal {
		// background: transparent !important;

		backdrop-filter: blur(10px);

		// .react-datepicker__day-name,
		.react-datepicker__day,
		.react-datepicker__time-name {
			width: 4rem;
			line-height: 4rem;
			font-size: 1.5rem;

			&--selected,
			&--keyboard-selected {
				background-color: $color-blue-1 !important;
				border-radius: 50% !important;
				color: $color-white;
			}

			&:hover {
				border-radius: 50% !important;
				background-color: rgba($color-blue-1, 0.5);
				color: $color-white;
			}
		}
		.react-datepicker__day-name {
			width: 4rem;
			line-height: 4rem;
			font-size: 1.5rem;
			font-size: 2rem;
			font-weight: 700;
		}
	}

	// &__portal,
}
// .react-datepicker__input-container {
// 	input {
// 		height: 3rem;
// 		border: solid 2px red !important;
// 	}
// }
