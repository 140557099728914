.overview-card {
	padding: 1.2rem;
	display: grid;
	align-items: center;
	gap: 2rem;
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2)
		),
		#f5f8fb;
	border-radius: 8px;

	&__top {
		display: flex;
		align-items: center;
		gap: 1rem;
		font-size: 16px;
		border-bottom: solid 1px #2f67e96c;
		padding-bottom: 1rem;
	}

	&__value {
		width: 100%;
		font-size: 16px;
		text-align: center;
		background-color: #2f68e933;
		color: #0447e0;
		border-radius: 8px;
		padding: 10px;
	}
	&__icon {
		width: max-content;
		height: max-content;
		padding: 1.5rem;
		display: flex;
		border-radius: 50%;

		&--green {
			background-color: $color-green;
		}
		&--orange {
			background-color: $color-orange-1;
		}

		&--purple {
			background-color: $color-purple-1;
		}
		&--blue {
			background-color: $color-blue-2;
		}
		&--red {
			background-color: $color-red-2;
		}

		img {
			object-fit: none;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	&__label {
		font-size: 1.4rem;
	}
	&__value {
		font-size: 2rem;
		font-weight: 700;
	}
}
