.hero {
    position: relative;
    max-width: calc(100% - 11.5rem);
    margin: auto;
    padding: 10rem 0;
    margin-top: 10rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    flex-wrap: wrap;

    @include screenSize(S) {
        width: calc(100% - 5rem);
    }

    &__text {
        width: 40%;
        flex: 1 0 auto;
        display: grid;

        gap: 2rem;

        &--heading {
            font-size: 36px;
        }

        &--lp {
            color: #ff7816;
        }
    }

    &__logo {
        width: 50%;

        img {
            width: 100%;
        }
    }

    &__images {
        float: 0 1 auto;
        position: relative;
        width: 50%;

        @media screen and (max-width: 835px) {
            display: none;
        }

        &:before {
            position: absolute;
            content: "";
            display: block;
            background-color: transparent;
            border: dotted 2px #ff7816;
            border-radius: 50%;
            width: 400px;
            height: 400px;
            right: 15px;
            top: -10%;
            z-index: -2;
        }

        &:after {
            position: absolute;
            content: "";
            display: block;
            background-color: transparent;
            border: dotted 2px #ff7816;
            border-radius: 50%;
            width: 200px;
            height: 200px;
            right: -10px;
            top: -1%;
            z-index: -2;
        }
    }

    &__image {
        display: block;
        position: absolute;
        width: 350px;
        right: 0;

        &--art {
            top: -10%;
            z-index: 1;
        }

        &--blob {
            top: -10%;
        }
    }
}

.features {
    display: grid;
    gap: 2rem;

    &__heading {
        font-size: 18px;
    }

    &__listing {
        // width: 50%;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        font-size: 14px;
    }
}

.feature {
    display: flex;
    gap: 1rem;
    align-items: center;
}
